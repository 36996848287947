/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import { Link } from 'gatsby';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import Layout from '@components/layout';
import { useJobPostQuery } from '@querys/useJobPostQuery';
import IntroBlock from '@components/other/introBlock';
import ArrowButton from '@components/UI/arrowButton';
import { BackButton } from '@components/UI/navButtons';

const JobPost = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { title, slug, intro, body, featuredImage, jobLink } = page || {};

  const { jobPosts } = useJobPostQuery();

  // filter out current listings and slice length to 2
  const relatedJobs = jobPosts
    ?.filter((listing) => listing?.slug?.current !== slug?.current)
    ?.slice(0, 2);

  const [hover, setHover] = useState(false);

  return (
    <Layout reversedHeader={true} disabledHeader={true}>
      <IntroBlock title={title} subtitle={intro} />
      <div className='flex-col lg:grid grid-cols-10 gap-x-[58px] px-gutter pt-[43px] lg:pt-space-m'>
        <div className='col-start-1 col-end-6 text-grey'>
          <SimplePortableText text={body} />
          <div className='blockH3 text-black xl:flex pt-[58px] '>
            <BackButton
              linkText='Back to jobs'
              linkUrl='/careers'
              extraClasses=''
            />
            <a href={jobLink} className='inline-block  xl:ml-20 mt-5 xl:mt-0'>
              <ArrowButton linkText='Apply now' />
            </a>
          </div>
        </div>
        <SanityImage
          image={featuredImage}
          className='hidden lg:block rounded-[50px] col-start-6 col-end-11 mt-20 lg:mt-0'
        />
      </div>
      {relatedJobs.length !== 0 && (
        <div className='px-gutter pt-space-m'>
          <h2 className='blockH2'>Similar Jobs</h2>
          <div className='pt-space-s flex flex-col space-y-[100px]'>
            {relatedJobs?.map((listing) => (
              <Link
                to={`/careers/${listing?.slug?.current}`}
                className='flex-col lg:grid grid-cols-10 gap-x-[58px] '
              >
                <h4 className='blockH6 text-grey col-start-1 col-end-3'>
                  {listing?.date}
                </h4>
                <div className='col-start-3 col-end-6 '>
                  <h2 className='blockH4'>{listing?.title}</h2>
                  <p className='line-clamp-5 blockH6 text-grey pt-5'>
                    {listing?.intro}
                  </p>
                  <Link
                    to={`/careers/${listing?.slug?.current}`}
                    className='blockH5 inline-block mt-[25px]'
                  >
                    {' '}
                    <ArrowButton linkText='Read more' imageHover={hover} />
                  </Link>
                </div>
                <div
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  className='rounded-[50px] col-start-6 col-end-11 aspect-w-4 aspect-h-3 relative overflow-hidden mr-10 mt-10 lg:mt-0'
                >
                  <SanityImage
                    image={listing?.featuredImage}
                    className='absolute inset-0 w-full h-full object-cover'
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default JobPost;

export const pageQuery = graphql`
  query standardJobPostQuery($slug: String!) {
    page: sanityJobPost(slug: { current: { eq: $slug } }) {
      title
      intro

      slug {
        current
      }
      featuredImage {
        ...ImageWithPreview
      }
      jobLink
      date(formatString: "DD MMMM YYYY")
      body: _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;
